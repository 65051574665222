import { useQuery } from '@tanstack/vue-query'

export const useCompanyIndustry = (prefix = null) => {
    const url = prefix ? `/api/${prefix}` : '/api'

    const { execute } = useApi(`${url}/company-industry`, 'GET')

    const { data, isLoading } = useQuery({
        queryKey: ['company-industries'],
        queryFn: () => execute(),
        staleTime: Infinity,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    const companyIndustries = computed(() => data.value ?? [])

    return { companyIndustries, execute, isLoading }
}
